import React from 'react'
import styled from "styled-components"
import Img from 'gatsby-image'
import { Link } from "gatsby"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"
import generateHTML from '../../utils/generateHTML';
import Content, { HTMLContent } from '../../components/Content';

const SectionWrap = styled.div`
  margin-top:90px;
`

const SectionItem = styled.div`  
  display:flex;	
  flex-wrap:wrap;
  padding-right: calc((100% - 1350px)/2);    
  align-items:center;
  ${BreakpointUp.lg`        
    &:nth-child(even){
      padding-right: 0;
      padding-left: calc((100% - 1350px)/2);  
      flex-direction: row-reverse;
      & .card{
        &:before{
          left:auto;
          right:-50%;
        }
      }
      & .SectionFigure{ 
        padding:0 100px 90px 45px;
      }
    }
  `}
`

const SectionFigure = styled.div`  
  width:100%;
  position:relative;
  padding:0 30px 90px 30px;
  ${BreakpointUp.sm`  
    padding:0 45px 90px 100px;
  `}
  ${BreakpointUp.lg`  
    flex: 0 0 50%;
    max-width: 50%;
	`}
`


const SectionContent = styled.div`  
position:relative;
width:100%;
  padding:0 30px 90px 30px;
  ${BreakpointUp.sm`  
    padding:0 45px 90px 100px;
  `}
  ${BreakpointUp.lg`  
    flex: 0 0 50%;
    max-width: 50%;
	`}
    & h3{
      margin-bottom: 15px;
      color:#020304;
      font-size: 28px;
      line-height: 38px;
      ${BreakpointUp.md`  
        font-size: 34px;
        line-height: 50px;
      `}
    }
`
const ReadMore = styled(Link)`  
  display: none;
  	${BreakpointUp.lg`
		display:block;	
		position:absolute;
		left:${props => props.left};
		right:${props => props.right};
		top:40%;
		transform: rotate(-90deg)translateY(-50%);
		z-index: 1;
		color:#020304;
		font-weight:700;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			height: 2px;
			transition: -webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transition: transform .4s cubic-bezier(.77,0,.175,1);
			transition: transform .4s cubic-bezier(.77,0,.175,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transform: translateZ(0) scaleX(1);
			background: #020304;
			transform-origin: right center;    
			transform: translateZ(0) scaleX(0);        
		}
		& .text{
			+ & svg{
			margin-left:5px;
			}
		}
		& svg{
			fill:#020304;
			transition: transform 0.3s ease-out;
		}
		&:hover{
			color:#020304;
      &:after {
				transform-origin: left center;        
				transform: translateZ(0) scaleX(1);
			}  
			svg{
				fill:#020304;
				transform: translateX(4px);
			}			
		}						
			
	`}
`
const Card = styled.div`
	display:flex;
	flex-direction:column;
  position:relative;
  &:before{
    content: '';
    left: -50%;
    width: 100%;
    background-color: #fff;
    position: absolute;
    height: calc(100% + 100px);
    top: -50px;
  }
`
const CardFigure = styled.div`	
	position:relative;
	width:100%;
  height: 400px;
	& .gatsby-image-wrapper{
		height:100%;
	}
`
const CardTip = styled.div`
	background-color:${props=>props.bgColor};
	color:${props=>props.color};
	font-size:14px;
	line-height:18px;
	font-weight:700;
	padding:5px 15px;
	position:absolute;
  left:${props=>props.left};  
  right:${props=>props.right};  
	z-index:1;
  top:50px; 
  
`
CardTip.defaultProps = {
	color:"#2F9800",
  bgColor:"rgba(47, 152, 0, 0.14)"
}

const ProductRoofStyle = ({ data }) => {
  const PostContent = HTMLContent || Content;
	return(
		<SectionWrap>
      {
        data.map((item, i)=> {
          return(
            <SectionItem>
              <SectionFigure className="SectionFigure">
                { i%2===0 ? (<ReadMore to={item.link?`${item.link}`: "/"} left="-40px"><span className="text">{item.linkText}</span><ArrowIcon/></ReadMore>):(<ReadMore to={item.link?`${item.link}`: "/"} right="-65px"><span className="text">{item.linkText}</span><ArrowIcon/></ReadMore>)}
                <Card className="card">
                  {item.heading.indexOf("Vertical")>-1? (<CardTip right="0" color="#2F9800" bgColor="#d5eacc">Best</CardTip>):(<></>)}
                  {item.heading.indexOf("A-Frame")>-1? (<CardTip color="#216ABC" bgColor="#d3e1f2">Better</CardTip>):(<></>)}
                  {item.heading.indexOf("Regular")>-1? (<CardTip right="0" color="#DE5600" bgColor="#f8ddcc">Good</CardTip>):(<></>)}
                  <CardFigure>
                    <Img fluid={item.image.fluid} />
                  </CardFigure>
                </Card>
              </SectionFigure>          
              <SectionContent>
                <h3>{item.heading}</h3>
                <PostContent
                  content={generateHTML(item.description.childMarkdownRemark.html)}
                />
              </SectionContent>
            </SectionItem>     
          )
        })
      }
    </SectionWrap>
	)
}

export default ProductRoofStyle